import React, { useEffect, useState, useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import API from "../../API";
import ThemeContext from "../../layouts/theme-setting/SettingContext";
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import '../../assets/css/pagination.css';
import { Grid, Button } from "@material-ui/core";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from "react-router-dom";
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    pagination: {

    }
};

const useStyles = makeStyles(styles);

export default function BlogsList() {
    const classes = useStyles();
    const history = useHistory();
    const themContext = useContext(ThemeContext);
    const [data, setData] = useState(false)
    const [keys, setKeys] = useState([]);
    const [values, setValues] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        API.readAll(`/blogs?page=${page}`).then(res => {
            setData(res);
            if (res.data.length > 0) {
                const arrKeys = [];
                const arrValues = [];
                // eslint-disable-next-line
                Object.keys(res.data[0]).map(key => {
                    if (key !== "__v" && key !== "description" && key !== "tags" && key !== "published_at" && key !== "created_at" && key !== "slug")
                        arrKeys.push(key);
                })
                // eslint-disable-next-line
                res.data.map(item => {
                    const tempArr = [];
                    // eslint-disable-next-line
                    Object.keys(item).map(key => {
                        if (key !== "__v" && key !== "description" && key !== "tags" && key !== "published_at" && key !== "created_at" && key !== "slug") {
                            if (key === "image") {
                                tempArr.push(`<img style="width:50px;" src="https://storage.tac.tacverse.com${item[key]}" />`);
                            }
                            else if (key === "_id") {
                                tempArr.push({ id: item[key] });
                            }
                            else {
                                tempArr.push((item[key]) + "");
                            }
                        }
                    })
                    arrValues.push(tempArr);
                })
                setKeys(arrKeys);
                setValues(arrValues)
            }
        }).catch(err => console.log(err.response.data))
    }, [page])


    const handlePageChange = (e) => {
        setPage(e.selected + 1);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        history.push(`?page=${e.selected + 1}&search=${history.location.search.split("search=")[1] ? history.location.search.split("search=")[1] : ''}`);
    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color={themContext.themeColor}>
                        <Grid container>
                            <Grid item xs={8} md={8}>
                                <h4 className={classes.cardTitleWhite}>Blogs List</h4>
                            </Grid>
                            <Grid item xs={4} md={4} style={{ marginTop: 'auto', marginBottom: 'auto', display: 'flex', justifyContent: 'end' }}>
                                <Link to="/admin/add-blog">
                                    <Button
                                        style={{ margin: "5px", color: 'white' }}
                                        variant="none"
                                    >
                                        <AddCircleIcon style={{ width: '40px', height: '40px' }} />

                                        {/* Add Partner */}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Table
                            tableHeaderColor={themContext.themeColor}
                            tableHead={keys}
                            tableData={values}
                            editPath={'edit-blog'}
                            APIName={'blogs'}
                        />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} >
                <div className="paginationConatiner">
                    {data &&
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={data ? data.pages : 0}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName={styles.active}
                            forcePage={page - 1}
                        />
                    }
                </div>
            </GridItem>
        </GridContainer >
    );
}
