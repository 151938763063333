import React, { useContext, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import ThemeContext from "../../layouts/theme-setting/SettingContext";
import { useForm } from "react-hook-form";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import API from "../../API.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../components/Loader/Loader.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function AddPartner() {
  const classes = useStyles();
  const history = useHistory();
  const themContext = useContext(ThemeContext);
  const { register, handleSubmit } = useForm();
  const [image, setImage] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
  }

  const onSubmit = (data) => {
    if (image) {
      let formData = new FormData();
      formData.append('image', image);
      formData.append('filter', data.filter === "true" ? true : false);
      setLoader(true);
      API.create("/partners", formData).then(res => {
        setLoader(false);
        history.push(`/admin/partners`);
      })
    }

  };
  return (
    <div>
      {loader && <Loader />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color={themContext.themeColor}>
                <h4 className={classes.cardTitleWhite}>Add Partner</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    {image &&
                      <img alt="" style={{ width: '100px', height: '100px', marginBottom: '10px', marginRight: '10px', objectFit: 'contain' }} src={URL.createObjectURL(image)} />
                    }
                    <input
                      type="file"
                      onChange={handleChangeImage}
                    />
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                      <Select
                        {...register("filter", { required: true })}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={"false"}
                        label="filter"
                      >
                        <MenuItem value={"false"}>None</MenuItem>
                        <MenuItem value={"true"}>Gray Scale</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type={'submit'} color={themContext.themeColor}>Save</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div >
  );
}
