
import axios from 'axios';

const API = {
    readAll: async (path) => {
        const Authaxios = axios.create({
            headers: {
                'Accept': 'application/json',
            }
        })
        try {
            const response = await Authaxios.get(`${process.env.REACT_APP_BASE_URL}${path}`)
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    },
    GetWithData: async (path, data) => {
        try {
            // const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${path}`, data)
            const response = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}${path}`,
                data: data
            });
            return response;
        } catch (err) {
            return err.response.data;
        }
    },
    read: async (path) => {
        const Authaxios = axios.create({
            headers: {
                'Accept': 'application/json',
            }
        })
        try {
            const response = await Authaxios.get(`${process.env.REACT_APP_BASE_URL}${path}`)
            return response.data;
        } catch (err) {
            return err.response.data;
        }
    },

    update: async (path, data, id) => {
        try {
            const token = localStorage.getItem('token');
            const authAxios = axios.create({
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token
                },
            })
            const response = await authAxios.put(`${process.env.REACT_APP_BASE_URL}${path}`, data)
            if (response.data.success) {
                return response.data;
            } else if (response.data.message.name === "JsonWebTokenError" || response.data.message.name === "TokenExpiredError") {
                localStorage.removeItem("token");
                window.location.reload();
                return response.data.success;
            }
            else {
                return response.data;
            }
        } catch (err) {
            console.log(err.response.data);
        }
    },

    create: async (path, data) => {
        try {
            const token = localStorage.getItem('token');
            const authAxios = axios.create({
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token
                },
            })
            const response = await authAxios.post(`${process.env.REACT_APP_BASE_URL}${path}`, data);
            console.log(response.data.success);
            if (response.data.success) {
                return response.data;
            } else if (response.data.message.name === "JsonWebTokenError" || response.data.message.name === "TokenExpiredError") {
                localStorage.removeItem("token");
                window.location.reload();
                return response.data.success;
            }
            else {
                return response.data;
            }
        } catch (err) {
            return err.response.data;
        }
    },

    delete: async (path) => {
        try {
            const token = localStorage.getItem('token');
            const authAxios = axios.create({
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token
                },
            })
            const response = await authAxios.delete(`${process.env.REACT_APP_BASE_URL}${path}`)
            if (response.data.success) {
                return response.data;
            } else if (response.data.message.name === "JsonWebTokenError" || response.data.message.name === "TokenExpiredError") {
                localStorage.removeItem("token");
                window.location.reload();
                return response.data.success;
            } else {
                return response.data;
            }
        } catch (err) {
            console.log(err.response.data);
        }
    },
}



export default API;