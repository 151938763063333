import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import API from "../../API.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const { tableHead, tableData, tableHeaderColor, editPath, APIName } = props;
  const [open, setOpen] = React.useState({ open: false, id: false });
  const handleToggleDialog = (id) => {
    if (id) {
      setOpen({ open: true, id: id })
    } else {
      setOpen({ open: false, id: false })
    }
  }

  const handleClickEdit = (id) => {
    history.push(`/admin/${editPath}/${id}`);
  }

  const handleClickDelete = (id) => {
    API.delete(`/${APIName}?id=${id}`).then(res => {
      window.location.reload();
    })
  }

  return (
    <div className={classes.tableResponsive}>
      {tableData.length > 0 ? (
        < Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={key}
                      style={{ textTransform: "uppercase" }}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                  style={{
                    textTransform: "uppercase",
                    textAlign: 'right'
                  }}
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData.map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  {prop.map((prop, key) => {
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        {
                          prop.id ? (prop.id) : (<div
                            dangerouslySetInnerHTML={{ __html: prop }}
                          />)
                        }
                      </TableCell>
                    );
                  })}
                  <TableCell className={classes.tableCell} key={key}>
                    <div style={{
                      display: "flex",
                      justifyContent: "end"
                    }}>
                      <EditIcon style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => handleClickEdit(prop[0].id)} />
                      <DeleteIcon style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => handleToggleDialog(prop[0].id)} />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (<div>No data please add new one</div>)
      }
      <Dialog
        open={open.open}
        onClose={() => handleToggleDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete?"}
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleToggleDialog()}>Disagree</Button>
          <Button onClick={() => handleClickDelete(open.id)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
