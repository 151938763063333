import React, { useState } from "react";
import SettingContext from "./SettingContext";

const SettingProvider = (props) => {
  const [themeColor, setThemeColor] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "warning");
  const [themeLayout, setThemeLayout] = useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : "admin");

  const handleChangeColor = (color) => {
    localStorage.setItem("theme", color);
    setThemeColor(color);
  }
  const handleChangeLang = (lang) => {
    localStorage.setItem("lang", lang);
    setThemeLayout(lang);
    window.location.href = `/${lang}`
    setTimeout(() => {
      window.location.reload();
    }, 1000)
  }

  return (
    <SettingContext.Provider
      value={{
        ...props,
        themeColor: themeColor,
        themeLayout: themeLayout,
        handleChangeColor: handleChangeColor,
        handleChangeLang: handleChangeLang
      }}
    >
      {props.children}
    </SettingContext.Provider>
  );
};

export default SettingProvider;
