import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import PartnersList from "./views/Partners/PartnersList";
import AddPartner from "./views/Partners/AddPartner";
import EditPartner from "./views/Partners/EditPartner.js";
import TestimonialsList from "./views/Testimonials/TestimonialsList.js";
import AddTestimonial from "./views/Testimonials/AddTestimonial.js";
import EditTestimonials from "./views/Testimonials/EditTestimonials.js";
import PortofoliosList from "./views/Portofolios/PortofoliosList.js";
import AddPortofolio from "./views/Portofolios/AddPortofolio.js";
import EditPortofolio from "./views/Portofolios/EditPortofolio.js";
import BlogsList from "./views/Blogs/BlogsList.js";
import AddBlog from "./views/Blogs/AddBlog.js";
import EditBlog from "./views/Blogs/EditBlog.js";
import DnaList from "./views/Dna/List.js";
import AddDna from "./views/Dna/Add";
import EditDna from "./views/Dna/Edit";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/admin",
  //   layoutAR: "/rtl",
  // },
  {
    path: "/partners",
    name: "Partners",
    rtlName: "",
    icon: Person,
    component: PartnersList,
    layout: "/admin",
    layoutAR: "/rtl",
  },
  {
    path: "/add-partner",
    component: AddPartner,
    layout: "/admin",
  },
  {
    path: `/edit-partner/:id`,
    component: EditPartner,
    layout: "/admin",
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    rtlName: "",
    icon: LibraryBooks,
    component: TestimonialsList,
    layout: "/admin",
    layoutAR: "/rtl",
  },
  {
    path: "/add-testimonial",
    component: AddTestimonial,
    layout: "/admin",
  },
  {
    path: `/edit-testimonials/:id`,
    component: EditTestimonials,
    layout: "/admin",
  },
  {
    path: "/portofolios",
    name: "Portofolios",
    rtlName: "",
    icon: BubbleChart,
    component: PortofoliosList,
    layout: "/admin",
    layoutAR: "/rtl",
  },
  {
    path: "/add-portofolio",
    component: AddPortofolio,
    layout: "/admin",
  },
  {
    path: `/edit-portofolio/:id`,
    component: EditPortofolio,
    layout: "/admin",
  },
  {
    path: "/blogs",
    name: "Blogs",
    rtlName: "",
    icon: LibraryBooks,
    component: BlogsList,
    layout: "/admin",
    layoutAR: "/rtl",
  },
  {
    path: "/add-blog",
    component: AddBlog,
    layout: "/admin",
  },
  {
    path: `/edit-blog/:id`,
    component: EditBlog,
    layout: "/admin",
  },
  {
    path: "/dna",
    name: "Our DNA",
    rtlName: "",
    icon: LibraryBooks,
    component: DnaList,
    layout: "/admin",
    layoutAR: "/rtl",
  },
  {
    path: "/add-dna",
    component: AddDna,
    layout: "/admin",
  },
  {
    path: `/edit-dna/:id`,
    component: EditDna,
    layout: "/admin",
  },
  // {
  //   path: "/stores",
  //   name: "Stores",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: StoresList,
  //   layout: "/admin",
  //   layoutAR: "/rtl",
  // },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
];

export default dashboardRoutes;
