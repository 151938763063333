import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import ThemeContext from "../../layouts/theme-setting/SettingContext.js";
import { useForm } from "react-hook-form";
import { TextField, InputLabel } from "@material-ui/core";
import API from "../../API.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import imageCompression from 'browser-image-compression';
import Loader from "../../components/Loader/Loader.js";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from 'react-select';
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  gridItem: {
    width: "100%",
    marginBottom: "10px"
  },
  select: {
    width: '100%'
  }
};

const useStyles = makeStyles(styles);

export default function EditService() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const themContext = useContext(ThemeContext);
  const { register, handleSubmit, setValue } = useForm();
  const [data, setData] = useState(false);
  const [image, setImage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [newService, setNewService] = React.useState("");
  const [selectedService, setSelectedService] = React.useState(false);


  useEffect(() => {
    API.readAll(`/services`).then(res => {
      const arr = [];
      // eslint-disable-next-line
      res.data.map(item => {
        arr.push({ value: item._id, label: item.name })
      })
      setOptions(arr);
    })
  }, [])

  const handleToggleDialog = () => {
    setOpen(!open)
  }
  const handleAdd = async () => {
    setLoader(true);
    const oldOptions = [...options];
    let formData = new FormData();
    formData.append("image", newService.image);
    formData.append("name", newService.name);
    await API.create("/services", formData).then(res => {
      oldOptions.push({ value: res.data._id, label: res.data.name })
      setLoader(false);
    })
    setLoader(false);
    setOptions(oldOptions)
    setOpen(false);
  }

  useEffect(() => {
    if (id) {
      API.read(`/our-dna?id=${id}`).then(res => {
        // eslint-disable-next-line
        Object.keys(res.data).map(key => {
          if (key !== "__v" && key !== "_id") {
            setValue(key, res.data[key])
          }
        })
        setData(res.data);
        const service = [];
        // eslint-disable-next-line
        res.data.services.map(item => {
          service.push({ value: item._id, label: item.name })
        })
        setSelectedService(service);
      })
    }
  }, [id])


  const handleChangeImage = async (e) => {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.5,
      initialQuality: 1,
      alwaysKeepResolution: 1,
    }
    try {
      setLoader(true);
      const compressedFile = await imageCompression(imageFile, options);
      setLoader(false);
      setImage(compressedFile);
      setValue(`image`, compressedFile)
    } catch (error) {
      console.log(error);
    }
  };



  const onSubmit = (data) => {
    if (data.image) {

      let formData = new FormData();
      // eslint-disable-next-line
      data.services.map((item, index) => {
        formData.append(`services[]`, item.value);
      })
      delete data.services;
      // eslint-disable-next-line
      Object.keys(data).map(key => {
        formData.append(key, data[key]);
      })
      API.update(`/our-dna?id=${id}`, formData).then(res => {
        history.push(`/admin/dna`);
      })
    } else {
      alert("Please Choose image");
    }
  };
  // const onSubmit = (data) => {
  //   API.update(`/our-dna?id=${id}`, data).then(res => {
  //     history.push(`/admin/dna`);
  //   })
  // };
  return (
    <div>
      {loader && <Loader />}
      {data &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color={themContext.themeColor}>
                  <h4 className={classes.cardTitleWhite}>Add Partner</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        className={classes.gridItem}
                        {...register("title", { required: true })}
                        defaultValue={data.title}
                        id="filled-basic"
                        label="Title"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        className={classes.gridItem}
                        {...register("description", { required: true })}
                        id="filled-basic"
                        label="Description"
                        rows={4}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="demo-simple-select-label">Services</InputLabel>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {options && selectedService &&
                          <Select
                            defaultValue={selectedService}
                            onChange={(e) => { setValue("services", e) }}
                            isMulti={true}
                            options={options}
                            className={classes.select} />
                        }
                        <Button onClick={() => { handleToggleDialog() }}><AddCircleIcon /></Button>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div>
                        <h4>
                          Image
                        </h4>
                      </div>
                      {image &&
                        <img alt="" style={{ width: '100px', height: '100px', marginBottom: '10px', marginRight: '10px', objectFit: 'contain' }} src={image ? URL.createObjectURL(image) : process.env.REACT_APP_IMAGE_URL + data.image} />
                      }
                      <input
                        type="file"
                        onChange={handleChangeImage}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button type={'submit'} color={themContext.themeColor}>Save</Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      }
      <Dialog
        open={open}
        onClose={handleToggleDialog}
        aria-labelledby="alert-dialog-title"
        fullWidth
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add new serivce"}
        </DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e) => setNewService({ ...newService, ...{ name: e.target.value } })}
            className={classes.gridItem}
            id="filled-basic"
            label="New Tag"
          />
          <GridContainer><GridItem xs={12} sm={12} md={6}>
            <div>
              <h4>
                Image
              </h4>
            </div>
            <input
              type="file"
              onChange={(e) => setNewService({ ...newService, ...{ image: e.target.files[0] } })}
              required
            />
          </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleDialog}>Cancell</Button>
          <Button onClick={handleAdd} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}
