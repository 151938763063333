import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import ThemeContext from "../../layouts/theme-setting/SettingContext";
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import API from "../../API.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  gridItem: {
    width: "100%",
    marginBottom: "10px"
  }
};

const useStyles = makeStyles(styles);

export default function EditTestimonials() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const themContext = useContext(ThemeContext);
  const { register, handleSubmit, setValue } = useForm();
  const [data, setData] = useState(false);

  useEffect(() => {
    if (id) {
      API.read(`/testimonials?id=${id}`).then(res => {
        // eslint-disable-next-line
        Object.keys(res.data).map(key => {
          if (key !== "__v" && key !== "_id") {
            setValue(key, res.data[key])
          }
        })
        setData(res.data)
      })
    }
  }, [id])

  const onSubmit = (data) => {
    API.update(`/testimonials?id=${id}`, data).then(res => {
      history.push(`/admin/testimonials`);
    })
  };
  return (
    <div>
      {data &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color={themContext.themeColor}>
                  <h4 className={classes.cardTitleWhite}>Add Partner</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        className={classes.gridItem}
                        {...register("title", { required: true })}
                        defaultValue={data.title}
                        id="filled-basic"
                        label="Title"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        className={classes.gridItem}
                        {...register("description", { required: true })}
                        defaultValue={data.description}
                        id="filled-basic"
                        label="Description"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        className={classes.gridItem}
                        {...register("client_name", { required: true })}
                        defaultValue={data.client_name}
                        id="filled-basic"
                        label="Client name"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        className={classes.gridItem}
                        {...register("client_position", { required: true })}
                        defaultValue={data.client_position}
                        id="filled-basic"
                        label="Client position"
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button type={'submit'} color={themContext.themeColor}>Save</Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      }
    </div >
  );
}
