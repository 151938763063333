import React, { useContext, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import ThemeContext from "../../layouts/theme-setting/SettingContext";
import { useForm } from "react-hook-form";
import { Grid, FormControl, Select, MenuItem, TextField } from "@material-ui/core";
import API from "../../API.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../components/Loader/Loader.js";
import imageCompression from 'browser-image-compression';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  gridItem: {
    width: "100%",
  },
  marginBottom: {
    marginBottom: "20px"
  }
};

const useStyles = makeStyles(styles);

export default function AddPortofolio() {
  const classes = useStyles();
  const history = useHistory();
  const themContext = useContext(ThemeContext);
  const { register, handleSubmit } = useForm();
  const [image, setImage] = useState(false);

  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);



  const handleChangeImage = async (e) => {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.05,
      initialQuality: 1,
      alwaysKeepResolution: true,
      useWebWorker: true,
    }
    try {
      setLoader(true);
      const compressedFile = await imageCompression(imageFile, options);
      setLoader(false);
      setImage(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (data) => {
    if (image) {
      let formData = new FormData();
      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('image', image);
      formData.append('type', data.type);
      formData.append('featured', data.featured === "true" ? true : false);
      setLoader(true);
      API.create("/portofolios", formData).then(res => {
        setLoader(false);
        if (res) {
          history.push(`/admin/portofolios`);
        }
        else {
          setError("File is too large");
        }
      })
    }
  };
  return (
    <div>
      {loader && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color={themContext.themeColor}>
                <h4 className={classes.cardTitleWhite}>Add Portofolio</h4>
              </CardHeader>
              <CardBody>
                <Grid container className={classes.marginBottom}>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      className={classes.gridItem}
                      {...register("title", { required: true })}
                      id="filled-basic"
                      label="Title"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      className={classes.gridItem}
                      {...register("description", { required: true })}
                      id="filled-basic"
                      label="Description"
                    />
                  </GridItem>
                </Grid>
                <Grid container className={classes.marginBottom}>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Type</InputLabel>
                      <Select
                        {...register("type", { required: true })}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Type"
                      >
                        <MenuItem value={"advertising"}>Advertising</MenuItem>
                        <MenuItem value={"branding"}>Branding</MenuItem>
                        <MenuItem value={"digital"}>Digital</MenuItem>
                        <MenuItem value={"identity"}>Identity</MenuItem>
                        <MenuItem value={"motion"}>Motion</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Featured</InputLabel>
                      <Select
                        {...register("featured", { required: true })}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={"false"}
                        label="Featured"
                      >
                        <MenuItem value={"false"}>No</MenuItem>
                        <MenuItem value={"true"}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid container>
                  <GridItem xs={12} sm={12} md={6}>
                    <div>
                      <h4 >
                        Image
                      </h4>
                    </div>
                    {image &&
                      <img alt="" style={{ width: '100px', height: '100px', marginBottom: '10px', marginRight: '10px', objectFit: 'contain' }} src={URL.createObjectURL(image)} />
                    }
                    <input
                      type="file"
                      onChange={handleChangeImage}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={6}>
                    <div>
                      <h4 >
                        Video (optional)
                      </h4>
                    </div>
                    {video &&
                      <video style={{ width: '100px', height: '100px', marginBottom: '10px', marginRight: '10px', objectFit: 'contain' }} autoplay>
                        <source src={URL.createObjectURL(video)} />
                      </video>
                    }
                    <input
                      type="file"
                      onChange={handleChangeVideo}
                    />
                  </GridItem> */}
                </Grid>
                {error && <p style={{ color: 'red', fontSize: '16px' }}>{error}</p>}
              </CardBody>
              <CardFooter>
                <Button type={'submit'} color={themContext.themeColor}>Save</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div >
  );
}
