import React, { useContext, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import ThemeContext from "../../layouts/theme-setting/SettingContext";
import { useForm } from "react-hook-form";
import { Grid, TextField, FormControl, Select as MySelect, MenuItem } from "@material-ui/core";
import API from "../../API.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../components/Loader/Loader.js";
import imageCompression from 'browser-image-compression';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from 'react-select';

import { EditorState, convertToRaw } from "draft-js";
import { convertFromHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  gridItem: {
    width: "100%",
  },
  marginBottom: {
    marginBottom: "50px"
  },
  select: {
    width: '100%',
    zIndex: '100'
  }
};


const useStyles = makeStyles(styles);

const wrapperStyle = {
  border: '1px solid #d9d9d9',
}

const editorStyle = {
  minHeight: '25rem',
  height: '100%',
  padding: '1rem',
  overflowY: "hidden"
}

export default function EditBlog() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const themContext = useContext(ThemeContext);
  const { register, handleSubmit, setValue } = useForm();
  const [image, setImage] = useState(false);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [open, setOpen] = React.useState(false);
  const [newTag, setNewTag] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState(false);
  const [tagsOptions, setTagsOptions] = React.useState(false);
  useEffect(() => {
    API.readAll(`/tags`).then(res => {
      const tags = [];
      // eslint-disable-next-line
      res.data.map(item => {
        tags.push({ value: item._id, label: item.tag })
      })
      setTagsOptions(tags);
    })
    if (id) {
      API.read(`/blogs?id=${id}`).then(res => {
        setData(res.data);
        const tags = [];
        // eslint-disable-next-line
        res.data.tags.map(item => {
          tags.push({ value: item._id, label: item.tag })
        })
        setSelectedTags(tags);
        setEditorState(EditorState.createWithContent(convertFromHTML(res.data.description)));
      })
    }
  }, [id])

  const handleChangeImage = async (e) => {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.05,
      initialQuality: 1,
      alwaysKeepResolution: true,
      useWebWorker: true,
    }
    try {
      setLoader(true);
      const compressedFile = await imageCompression(imageFile, options);
      setLoader(false);
      setImage(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadCallback = async (file, callback) => {
    const options = {
      maxSizeMB: 0.05,
      initialQuality: 1,
      alwaysKeepResolution: true,
      useWebWorker: true,
    }
    const compressedFile = await imageCompression(file, options);
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const form_data = new FormData();
        form_data.append("image", compressedFile);
        API.create(`/blogs?image=1`, form_data).then(res => {
          // setValue("thumbnail", `${process.env.REACT_APP_IMAGE_URL}${res.data}`);
          resolve({ data: { link: `${process.env.REACT_APP_IMAGE_URL}${res.data}` } });
        })
      };
      reader.readAsDataURL(file);
    });
  };

  const config = {
    image: {
      uploadCallback: uploadCallback
    },
  };

  const handleToggleDialog = () => {
    setOpen(!open)
  }
  const handleAdd = async () => {
    setLoader(true);
    const oldOptions = [...tagsOptions];
    if (newTag !== "") {
      await API.create("/tags", { tag: newTag }).then(res => {
        oldOptions.push({ value: res.data._id, label: res.data.tag })
        setLoader(false);
      })
    }
    setTagsOptions(oldOptions);
    setOpen(false);
    setLoader(false);
  }

  const handleChangeTags = (e) => {
    setSelectedTags(e)
  }


  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append('title', data.title);
    // formData.append('sub_title', data.sub_title);
    formData.append('type', data.type);
    formData.append('slug', data.slug);
    formData.append('published_at', data.published_at);
    // eslint-disable-next-line
    selectedTags.map((item, index) => {
      formData.append(`tags[]`, item.value);
    })
    if (data.description)
      formData.append('description', data.description);
    // eslint-disable-next-line
    { image && formData.append('image', image); }
    setLoader(true);
    API.update(`/blogs?id=${id}`, formData).then(res => {
      setLoader(false);
      if (res) {
        history.push(`/admin/blogs`);
      }
      else {
        setError("File is too large");
      }
    })
  };

  return (
    <div>
      {loader && <Loader />}
      {data &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color={themContext.themeColor}>
                  <h4 className={classes.cardTitleWhite}>Edit Blog</h4>
                </CardHeader>
                <CardBody>
                  <Grid container className={classes.marginBottom}>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        className={classes.gridItem}
                        {...register("title", { required: true })}
                        id="filled-basic"
                        label="Title"
                        defaultValue={data.title}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <MySelect
                          {...register("type", { required: true })}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={data.type}
                          label="Type"
                        >
                          <MenuItem value={"News"}>News</MenuItem>
                          <MenuItem value={"Articles"}>Articles</MenuItem>
                        </MySelect>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Grid container className={classes.marginBottom}>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {tagsOptions && selectedTags &&
                          <Select
                            onChange={handleChangeTags}
                            isMulti={true}
                            options={tagsOptions}
                            defaultValue={selectedTags}
                            className={classes.select} />
                        }
                        <Button onClick={() => { handleToggleDialog() }}><AddCircleIcon /></Button>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        className={[classes.gridItem, "mt-2"]}
                        {...register("slug", { required: true })}
                        id="filled-basic"
                        label="Slug"
                        defaultValue={data.slug ? data.slug : ""}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container className={classes.marginBottom}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Editor
                        toolbar={config}
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={(e) => {
                          setEditorState(e);
                          setValue(`description`, draftToHtml(convertToRaw(editorState.getCurrentContent())), { shouldValidate: false })
                        }}
                        wrapperStyle={wrapperStyle}
                        editorStyle={editorStyle}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={4}>
                      <div>
                        <h4 >
                          Image
                        </h4>
                      </div>
                      <div>
                        <img alt="img" style={{ width: '100px', height: '100px', marginBottom: '10px', marginRight: '10px', objectFit: 'contain' }} src={image ? URL.createObjectURL(image) : process.env.REACT_APP_IMAGE_URL + data.image} />
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleChangeImage}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="demo-simple-select-label">Published Date</InputLabel>
                      <TextField
                        type={"date"}
                        defaultValue={new Date(data.published_at).toISOString().substring(0, 10)}
                        className={classes.gridItem}
                        {...register("published_at", { required: true })}
                        id="filled-basic"
                      />
                    </GridItem>
                  </Grid>
                  {error && <p style={{ color: 'red', fontSize: '16px' }}>{error}</p>}

                </CardBody>
                <CardFooter>
                  <Button type={'submit'} color={themContext.themeColor}>Save</Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
      }
      <Dialog
        open={open}
        onClose={handleToggleDialog}
        aria-labelledby="alert-dialog-title"
        fullWidth
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add new tag"}
        </DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e) => { setNewTag(e.target.value) }}
            className={classes.gridItem}
            id="filled-basic"
            label="New Tag"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleDialog}>Cancell</Button>
          <Button onClick={handleAdd} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}
